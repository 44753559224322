<!-- resources/js/Components/home/AboutSection.vue -->
<script setup>
import { ref } from 'vue';
import IndigoButton from '@/Components/IndigoButton.vue';

const isExpanded = ref(false);
const toggleExpand = () => {
    isExpanded.value = !isExpanded.value;
};
</script>

<template>
    <section class="py-16">
        <div class="container mx-auto text-center px-4">
            <div class="lightpanel p-8 rounded-lg shadow-md max-w-3xl mx-auto">
                <h2 class="text-3xl md:text-4xl font-bold mb-6 text-white">Pixyl - Revolutionizing Digital Signage for Everyone</h2>
                <p class="text-neutral-300 mb-4">
                    Pixyl was created with a simple mission: to bring the advanced technology of digital signage to bars, clubs, and businesses in low-income areas. We believe that everyone should have access to powerful tools that enhance their business, regardless of location or budget.
                </p>
                <p class="text-neutral-300 mb-4" v-show="isExpanded">
                    Our platform offers an affordable and easy-to-use solution that allows any business to leverage digital signage just like major corporations do. Pixyl is designed to be accessible, intuitive, and impactful, enabling users to manage content, create playlists, and display media across multiple screens effortlessly. Our goal is to level the playing field, providing the same high-quality, professional digital signage experience to those who may not have had access to such technology before.
                </p>
                <IndigoButton @click="toggleExpand">
                    {{ isExpanded ? 'Read Less' : 'Read More' }}
                </IndigoButton>
            </div>
        </div>
    </section>
</template>

<style scoped>
/* Add any additional styling if needed */
</style>
