<script setup>
import { ref } from 'vue';

const features = ref([
    {
        icon: ['fas', 'play-circle'],
        title: 'Effortless Content Management',
        description: 'Easily upload, schedule, and manage your content across multiple screens with our intuitive platform.',
    },
    {
        icon: ['fas', 'signal'],
        title: 'Real-Time Updates',
        description: 'Make instant updates to your screens from anywhere. Ensure your content is always fresh and relevant.',
    },
    {
        icon: ['fas', 'mobile-alt'],
        title: 'Cross-Device Compatibility',
        description: 'Control your digital signage from any device, whether it’s a desktop, tablet, or smartphone.',
    },
    {
        icon: ['fas', 'chart-line'],
        title: 'Advanced Analytics',
        description: 'Track the performance of your content with detailed analytics, allowing you to optimize for maximum engagement.',
    },
]);
</script>

<template>
    <section class="py-20 bg-black text-white">
        <div class="container mx-auto text-center">
            <h2 class="text-4xl font-extrabold mb-12 text-blue-500">Why Choose Pixyl?</h2>
            <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div v-for="(feature, index) in features" :key="index"
                    class="flex flex-col items-center md:items-start text-center md:text-left">
                    <div class="bg-blue-500 p-4 rounded-full mb-4 shadow-lg">
                        <font-awesome-icon :icon="feature.icon" class="text-3xl text-black" />
                    </div>
                    <h3 class="text-xl font-semibold mb-2">{{ feature.title }}</h3>
                    <p class="text-sm text-gray-300">{{ feature.description }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
/* Additional styling */
.bg-blue-500 {
    background-color: #00A3FF; /* Adjust to match the neon blue in the logo */
}
.text-blue-500 {
    color: #00A3FF; /* Adjust to match the neon blue in the logo */
}
</style>
