<script setup>
const testimonials = [
    {
        quote: "Pixyl transformed our venue with stunning digital displays. Our customers love it!",
        name: "Jordan M.",
        position: "Bar Owner",
        image: "/storage/images/jordan.jpg" // Replace with the correct path
    },
    {
        quote: "Setting up screens and playlists was a breeze. Pixyl is a game-changer!",
        name: "Samantha T.",
        position: "Club Manager",
        image: "/storage/images/samantha.jpg" // Replace with the correct path
    },
    {
        quote: "Our promotions are more dynamic and engaging thanks to Pixyl's easy-to-use platform.",
        name: "Lucas R.",
        position: "Marketing Lead",
        image: "/storage/images/lucas.jpg" // Replace with the correct path
    }
];
</script>

<template>
    <section class="py-16">
        <div class="container mx-auto px-4">
            <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
                <div v-for="(testimonial, index) in testimonials" :key="index"
                    class="lightpanel p-6 rounded-lg shadow-lg transform transition hover:scale-105 duration-300">
                    <blockquote class="text-gray-300 mb-4">
                        <p class="italic">"{{ testimonial.quote }}"</p>
                    </blockquote>
                    <div class="flex items-center mb-4">
                        <img :src="testimonial.image" class="w-12 h-12 rounded-full mr-4 border-2 border-neon-pink">
                        <div>
                            <p class="font-bold text-lg text-neon-pink">{{ testimonial.name }}</p>
                            <p class="text-neutral-300">{{ testimonial.position }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.border-neon-pink {
    border-color: #00A3FF;
}

.text-neon-pink {
    color: #00A3FF;
}

.shadow-lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

.transform {
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transition {
    transition: all 0.3s ease-in-out;
}

.hover\:scale-105:hover {
    transform: scale(1.05);
}
</style>
