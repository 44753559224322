// src/composables/useIntersectionObserver.js
import { ref, onUnmounted } from 'vue';

export function useIntersectionObserver(callback, options = {}) {
    const observer = ref(null);
    const element = ref(null);

    const observe = (el) => {
        if (observer.value) observer.value.disconnect();
        observer.value = new IntersectionObserver(([entry]) => callback(entry), options);
        observer.value.observe(el);
    };

    onUnmounted(() => {
        if (observer.value) observer.value.disconnect();
    });

    return {
        observe,
        element,
    };
}
