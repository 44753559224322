<!-- resources/js/Components/home/FAQSection.vue -->
<script setup>
import { ref } from 'vue';

const faqs = ref([
    {
        question: "What is Pixyl?",
        answer: "Pixyl is a digital signage platform that allows you to easily create, manage, and display content across multiple screens. Perfect for businesses, events, and advertising."
    },
    {
        question: "How do I upload media to Pixyl?",
        answer: "Uploading media to Pixyl is simple. Just go to your content library, click 'Upload', and select the images or videos you want to add. These files will be available for use in your playlists."
    },
    {
        question: "Can I assign a playlist to multiple screens?",
        answer: "Yes, you can assign a single playlist to multiple screens. This allows you to display the same content across different locations simultaneously."
    },
    {
        question: "How do I create a playlist?",
        answer: "Creating a playlist in Pixyl involves selecting media from your library, arranging them in your desired order, and setting the duration for each item. Once done, you can assign the playlist to any of your screens."
    },
    {
        question: "How can I access my screen remotely?",
        answer: "You can access your screen remotely by visiting your unique URL in any web browser. For example, visit pixyl.app/MyScreenName-0001 to see your content live."
    },
    {
        question: "What file formats are supported?",
        answer: "Pixyl supports a variety of file formats, including JPEG, PNG, GIF for images, and MP4 for videos. Ensure your files are in these formats for smooth playback."
    },
    {
        question: "Is there a limit to the number of screens I can create?",
        answer: "No, Pixyl does not limit the number of screens you can create. You can manage as many screens as you need, each with its own unique playlist."
    },
]);

const toggleFAQ = (index) => {
    faqs.value[index].isOpen = !faqs.value[index].isOpen;
};
</script>

<template>
    <section class="py-16">
        <div class="container mx-auto text-center px-4">
            <div class="max-w-4xl mx-auto">
                <!-- Section Title -->
                <h2 class="text-3xl md:text-4xl text-neutral-300 font-bold mb-8">Frequently Asked Questions</h2>

                <!-- FAQ Items -->
                <div class="space-y-4">
                    <div v-for="(faq, index) in faqs" :key="index"
                        class="lightpanel rounded-lg shadow-md p-4 cursor-pointer hover:bg-gray-900 transition duration-200"
                        @click="toggleFAQ(index)">
                        <div class="flex justify-between items-center">
                            <p class="text-lg text-neutral-300 font-semibold text-left">{{ faq.question }}</p>
                            <span class="text-neutral-300">{{ faq.isOpen ? '-' : '+' }}</span>
                        </div>
                        <transition name="fade">
                            <div v-show="faq.isOpen" class="mt-2 text-left text-neutral-300 whitespace-pre-line"
                                v-html="faq.answer"></div>
                        </transition>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
