<script setup>
import { ref, onMounted } from 'vue';
import CountUp from 'vue-countup-v3';
import { useIntersectionObserver } from '@/composables/Home/useIntersectionObserver';
import { faTv, faFileVideo, faMapMarkedAlt, faChartLine } from '@fortawesome/free-solid-svg-icons';

const isIntersecting = ref(false);

const callback = (entry) => {
    if (entry.isIntersecting) {
        isIntersecting.value = true;
    }
};

const { observe, element: statsRef } = useIntersectionObserver(callback);

onMounted(() => {
    if (statsRef.value) {
        observe(statsRef.value);
    }
});

const stats = [
    {
        icon: faTv,
        value: 2000,
        label: 'Screens Managed',
    },
    {
        icon: faFileVideo,
        value: 500,
        label: 'Playlists Created',
    },
    {
        icon: faMapMarkedAlt,
        value: 100,
        label: 'Locations Served',
    },

];
</script>

<template>
    <section ref="statsRef" class="py-8 text-center text-white relative">
        <div class="container mx-auto relative z-10">
            <!-- Header Text -->
            <div class="mb-8">
                <h2 class="text-2xl md:text-4xl font-bold text-white mt-6">
                    It’s not just <span class="text-blue-500">NUMBERS</span>, it’s <span
                        class="text-blue-500">IMPACT</span> we <span class="text-blue-500">CREATED</span> with
                    <span class="text-blue-500">PIXEL PERFECT DISPLAY</span>.
                </h2>
            </div>

            <!-- Stats -->
            <div class="flex flex-wrap justify-center mt-4">
                <article v-for="(stat, index) in stats" :key="index" class="flex flex-col items-center md:w-1/4 my-4">
                    <font-awesome-icon :icon="stat.icon" class="w-9 h-9 mb-2" />
                    <p v-if="isIntersecting">
                        <CountUp :end-val="stat.value" :duration="3" class="text-2xl font-bold text-white" />
                    </p>
                    <span class="text-gray-300">{{ stat.label }}</span>
                </article>
            </div>
        </div>
        <div class="border-wrapper absolute inset-0 z-0 pointer-events-none">
            <div class="border-top"></div>
            <div class="border-side left-0"></div>
            <div class="border-side right-0"></div>
            <div class="border-bottom"></div>
        </div>
    </section>
</template>

<style scoped>
.border-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}

.border-top,
.border-bottom {
    height: 4px;
    background: linear-gradient(to right, transparent, #00A3FF, transparent);
}

.border-top {
    top: 0;
    left: 5%;
    right: 5%;
    position: absolute;
}

.border-bottom {
    bottom: 0;
    left: 5%;
    right: 5%;
    position: absolute;
}

.border-side {
    position: absolute;
    top: 5%;
    bottom: 5%;
    width: 4px;
    background: linear-gradient(to bottom, transparent, #00A3FF, transparent);
}

.text-blue-500 {
    color: #00A3FF;
}
</style>
