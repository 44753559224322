<script setup>
import { Link } from '@inertiajs/vue3';
import IndigoButton from '@/Components/IndigoButton.vue';
import { computed } from 'vue';

// Using the alias created in the Vite config for easier path management
const logoUrl = computed(() => new URL('@/images/word.png', import.meta.url).href);

</script>

<template>
    <section class="w-full flex flex-col items-center text-white py-16">
        <!-- Pixyl Logo -->
        <img src="/storage/images/word.png" alt="Pixyl Logo" class="mb-8 w-40 h-auto">

        <div class="text-center max-w-xl mx-auto">
            <h1 class="text-6xl font-extrabold mb-6 neon-blue-text leading-tight">
                Create, Display, & Engage
            </h1>
            <p class="mb-8 text-neutral-300 text-lg leading-relaxed">
                Revolutionize your digital signage experience with Pixyl. Manage content effortlessly and captivate your audience.
            </p>

            <Link href="/register">
                <IndigoButton class="text-lg px-8 py-4">
                    Get Started
                </IndigoButton>
            </Link>
            <p class="mt-4 text-neutral-300 text-sm">
                <span class="text-neon-blue">*</span> Affordable and easy to use
            </p>
        </div>

        <!-- <div class="flex justify-center space-x-8 mt-12">
            <img src="/storage/images/lou.jpg" alt="Display Example 1"
                class="w-32 h-32 rounded-lg bg-black p-2 shadow-neon transform hover:scale-105 transition-transform duration-300">
            <img src="/storage/images/amy.jpg" alt="Display Example 2"
                class="w-32 h-32 rounded-lg bg-black p-2 shadow-neon transform hover:scale-105 transition-transform duration-300">
            <img src="/storage/images/martha.jpg" alt="Display Example 3"
                class="w-48 h-48 rounded-lg bg-black p-2 shadow-neon transform hover:scale-105 transition-transform duration-300">
            <img src="/storage/images/zack.jpg" alt="Display Example 4"
                class="w-48 h-48 rounded-lg bg-black p-2 shadow-neon transform hover:scale-105 transition-transform duration-300">
        </div> -->
    </section>
</template>

<style scoped>
/* Adjusted neon blue color to match the Pixyl logo */
.neon-blue-text {
    color: #ebebeb; /* Adjusted Neon Blue */
    text-shadow: 0 0 8px #00aeff, 0 0 16px #00aeff, 0 0 24px #00aeff;
}

.text-neutral-300 {
    color: rgba(255, 255, 255, 0.7);
}

.text-neon-blue {
    color: #00aeff; /* Adjusted Neon Blue */
}

.shadow-neon {
    box-shadow: 0 0 10px #00aeff, 0 0 20px #00aeff, 0 0 30px #00aeff; /* Adjusted Neon glow effect */
}

/* Rounded rectangular square instead of fully rounded */
.rounded-lg {
    border-radius: 15px; /* Rounded rectangle */
}

.hover\:scale-105:hover {
    transform: scale(1.05);
}
</style>
