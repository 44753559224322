<script setup>
import { ref } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import IndigoButton from '@/Components/IndigoButton.vue';
const steps = ref([
    {
        icon: ['fas', 'upload'],
        title: 'Upload Media',
        description: 'Add your images and videos to the content library to begin creating engaging displays.',
    },
    {
        icon: ['fas', 'list-ul'],
        title: 'Create a Playlist',
        description: 'Organize your media into playlists and set the duration for each image or video.',
    },
    {
        icon: ['fas', 'tv'],
        title: 'Create a Screen',
        description: 'Set up your screens and assign the playlists you’ve created to one or multiple screens.',
    },
    {
        icon: ['fas', 'globe'],
        title: 'Access Your Screen',
        description: 'View your content on any browser via your unique URL, like pixyl.app/MyScreenName-0001.',
    },
]);
</script>

<template>
    <section class="py-20 bg-black text-white">
        <div class="container mx-auto text-center">
            <h2 class="text-4xl font-extrabold mb-12 text-blue-500">How Does Pixyl Work?</h2>
            <div class="flex flex-wrap justify-around items-center space-y-8 md:space-y-0">
                <div v-for="(step, index) in steps" :key="index"
                    class="w-full md:w-1/4 flex flex-col items-center text-center px-4">
                    <div class="bg-blue-500 p-6 rounded-full mb-4 shadow-lg">
                        <font-awesome-icon :icon="step.icon" class="text-5xl text-black" />
                    </div>
                    <h3 class="text-2xl font-semibold mb-2">{{ step.title }}</h3>
                    <p class="text-sm text-gray-300">{{ step.description }}</p>
                </div>
            </div>
        </div>
    </section>
</template>

<style scoped>
.bg-blue-500 {
    background-color: #00A3FF; /* Matches the neon blue from the Pixyl logo */
}
.text-blue-500 {
    color: #00A3FF; /* Matches the neon blue from the Pixyl logo */
}
</style>
